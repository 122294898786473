import React, { useEffect, useState } from "react";
import axios from "axios";
import Loading from '../comps/loading';

function AboutUs() {
  const [content, setContent] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get("/api/pages.php?page=1");
      const data = response.data;
      setContent(data);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  return (
    <div className="aboutus">
      {content.length > 0 ? (
        <>
          <h1>{content[0].page_title}</h1>
          <p>{content[0].page_content}</p>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
}

export default AboutUs;
