import React, { useEffect, useState } from 'react';
import axios from 'axios';

function AddProduct() {
  const [categories, setcategory] = useState([]);

  useEffect(() => {
    getCategory();
  }, []);

  const getCategory = async () => {
    try {
      const res = await axios.get('/api/categories.php');
      const data = res.data;
      setcategory(data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="categorypage container">
      <h1>הוספת מוצרים</h1>
      <form
        method="POST"
        action="/api/products.php"
        enctype="multipart/form-data"
      >
        <div className="mb-3">
          <label className="form-label">שם המוצר</label>
          <input type="text" className="form-control" name="ProductName" />
        </div>
        <div className="mb-3">
          <label className="form-label">קטגוריה</label>
          <select name="CategoryID" className="form-control">
            {categories.map((category) => (
              <option value={`${category.id}`} key={category._id}>
                {category.Category_Name}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-3">
          <label className="form-label">תמונה ראשית</label>
          <input type="file" className="form-control" name="Image" />
        </div>
        <div className='row'>
        <div className="col mb-3">
          <label className="form-label">תמונת מוצר 1</label>
          <input type="file" className="form-control" name="Image1" />
        </div>
        <div className="col mb-3">
          <label className="form-label">תמונת מוצר 2</label>
          <input type="file" className="form-control" name="Image2" />
        </div>
        <div className="col mb-3">
          <label className="form-label">תמונת מוצר 3</label>
          <input type="file" className="form-control" name="Image3" />
        </div>
        <div className="col mb-3">
          <label className="form-label">תמונת מוצר 4</label>
          <input type="file" className="form-control" name="Image4" />
        </div>
        </div>
        <div className="mb-3">
          <label className="form-label">מחיר רגיל</label>
          <input type="number" className="form-control" name="Price" />
        </div>
        <div className="mb-3">
          <label className="form-label">מחיר מבצע</label>
          <input type="number" className="form-control" name="SalePrice" />
        </div>
        <div className="mb-3">
          <label className="form-label">כמות במלאי</label>
          <input type="number" className="form-control" name="Stock" />
        </div>
        <div className="mb-3">
          <label className="form-label">מותג</label>
          <input type="text" className="form-control" name="Brand" />
        </div>
        <div className="mb-3">
          <label className="form-label">תיאור המוצר</label>
          <input type="text" className="form-control" name="Description" />
        </div>
        <button type="submit" className="btn btn-primary">
          הוספת מוצר
        </button>
      </form>
    </div>
  );
}

export default AddProduct;
