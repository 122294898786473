import React, { useState } from 'react';
import '../../css/admin.css';
import AddProduct from './comps/products/addProduct';
import Orders from './comps/orders/orders';
import SideMenu from '../admin/comps/sidemenu';
import Users from './comps/users/users';
import Categories from './comps/categories/categories';
import AddCategories from './comps/categories/addcategories';
import EditCategories from './comps/categories/editcategory';
import AllProduct from './comps/products/allproducts';

function DeshboardAdmin() {
  const [currentScreen, setCurrentScreen] = useState('products');

  const Layout = ({ children }) => {
    return (
      <>
        <div className="container-fluid">
          <div className="row flex-nowrap sideMenu">
            <SideMenu setCurrentScreen={setCurrentScreen} />
            <div className="col py-3" id="rootAdmin">
              {children}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Layout>
        {currentScreen === 'products' && <AddProduct />}
        {currentScreen === 'allproducts' && <AllProduct />}
        {currentScreen === 'orders' && <Orders />}
        {currentScreen === 'users' && <Users />}
        {currentScreen === 'categories' && (
          <Categories setCurrentScreen={setCurrentScreen} />
        )}
        {currentScreen === 'addcategories' && <AddCategories />}
        {currentScreen === 'editcategory' && <EditCategories />}
      </Layout>
    </>
  );
}

export default DeshboardAdmin;
