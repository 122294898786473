const Loading = () => {

    return (
        <div className="loading">
        <p>
            טוען...
        </p>
        <img className="loadingGif" src="../../images/icons/loading.gif" />
        </div>
    )

}

export default Loading;