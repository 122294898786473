import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

function Login() {
  const [loggedInUsername, setLoggedInUsername] = useState("");
  const [loginMessage, setLoginMessage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const storedUser = localStorage.getItem("loggedInUser");
    if (storedUser) {
      setLoggedInUsername(storedUser);
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();

    const email = e.target.Email.value;
    const password = e.target.Password.value;

    try {
      const response = await fetch(
        `/api/users.php?Email=${email}&Password=${password}`
      );

      if (response.ok) {
        const data = await response.json();
        setLoggedInUsername(data.username);
        localStorage.setItem("loggedInUser", data.username);
        setLoginMessage(true);

        setTimeout(() => {
          navigate("/");
        }, 2000);
      } else {
        setLoginMessage(false);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleLogout = () => {
    setLoggedInUsername("");
    localStorage.removeItem("loggedInUser");
  };

  useEffect(() => {
    if (loginMessage !== "") {
      const timer = setTimeout(() => {
        setLoginMessage("");
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [loginMessage]);

  return (
    <div className="registerBlock">
      <div className="row">
        <div className="panel panel-primary">
          {loginMessage != null ? (
            <div
              className={`alert ${
                loginMessage === true ? "alert-success" : "alert-danger"
              }`}
              role="alert"
            >
              {loginMessage === true
                ? "התחברת בהצלחה! מיד תועבר..."
                : "ניסיון ההתחברות כשל, יש לנסות שוב!"}
            </div>
          ) : null}

          <div className="panel-body">
            <form
              method="GET"
              action="/api/users.php"
              role="form"
              onSubmit={handleLogin}
            >
              <div className="form-group">
                <h2>התחברות:</h2>
              </div>
              <div className=" form-group registerFormGrid">
                <div className="form-group">
                  <label className="control-label" htmlFor="signupEmail">
                    מייל
                  </label>
                  <input
                    id="Email"
                    type="email"
                    name="Email"
                    maxLength="50"
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label className="control-label" htmlFor="signupPassword">
                    סיסמא
                  </label>
                  <input
                    id="Password"
                    type="password"
                    name="Password"
                    maxLength="25"
                    className="form-control"
                    length="40"
                  />
                </div>
                {/* <div className="form-group">
                  <label className="control-label" htmlFor="signupPasswordagain">
                    אימות סיסמא
                  </label>
                  <input
                    id="signupPasswordagain"
                    type="password"
                    maxLength="25"
                    className="form-control"
                  />
                </div> */}
              </div>
              <div className="form-group">
                <br />
                <button
                  id="signupSubmit"
                  type="submit"
                  className="btn btn-primary"
                >
                  התחבר
                </button>
              </div>
              <br />
              <hr />
              <p>
                עדיין אין לך חשבון? <Link to="/register">הרשמה</Link>
              </p>
            </form>
          </div>
        </div>
      </div>
      {loggedInUsername && <p>שלום, {loggedInUsername}!</p>}
    </div>
  );
}

export default Login;
