import React from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const ImageCarousel = () => {
    return (
      <div className='carouselBlock'>
        <Carousel>
          <Carousel.Item>
            <img
              className="d-block w-100 carouselimg"
              src="https://i.pinimg.com/originals/ca/e7/2c/cae72ce86998abcadd5051acd91a696b.jpg"
              alt="Slide 1"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 carouselimg"
              src="https://www.stormforcegaming.co.uk/wp-content/uploads/slider/cache/9d7d40f847b6c9fd75210f1595be03b4/Configurator-Banner-update.jpg"
              alt="Slide 2"
            />
          </Carousel.Item>
        </Carousel>
      </div>
    );
  };
  
  export default ImageCarousel;
  