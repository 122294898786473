import React from 'react';
import Products from '../../comps/products';

const RelatedProducts = ({ category, proid }) => {
    return (
        <div>
            <h3>מוצרים נוספים שיכולים לעניין אותך:</h3>
            <Products query={`/api/products.php?filter=${category}&exclude=${proid}&limitrealse=4`} />
        </div>
    );
};

export default RelatedProducts;
