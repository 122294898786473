import React from 'react';

function SideMenu({ setCurrentScreen }) {
  return (
    <div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0 bg-dark">
      <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
        <a
          href="/"
          id="logo"
          className="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none"
        >
          <span className="fs-5 d-none d-sm-inline">
            <img src="./logo.png" />
          </span>
        </a>
        <ul
          className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start"
          id="menu"
        >
          <li className="nav-item">
            <a href="#" className="nav-link align-middle px-0">
              <i className="fs-4 bi-house"></i>{' '}
              <span className="ms-1 d-none d-sm-inline">בית</span>
            </a>
          </li>
          <li>
            <a
              href="#submenu1"
              data-bs-toggle="collapse"
              className="nav-link px-0 align-middle"
            >
              <i className="fs-4 bi-speedometer2"></i>{' '}
              <span className="ms-1 d-none d-sm-inline">לוח בקרה</span>{' '}
            </a>
            <ul
              className="collapse show nav flex-column ms-1"
              id="submenu1"
              data-bs-parent="#menu"
            >
              <li className="w-100">
                <button className="nav-link px-0">
                  <span className="d-none d-sm-inline">Item</span> 1{' '}
                </button>
              </li>
              <li>
                <button className="nav-link px-0">
                  <span className="d-none d-sm-inline">Item</span> 2{' '}
                </button>
              </li>
            </ul>
          </li>
          <li>
            <button
              className="nav-link px-0 align-middle"
              onClick={() => setCurrentScreen('orders')}
            >
              <i className="fs-4 bi-table"></i>{' '}
              <span className="ms-1 d-none d-sm-inline">הזמנות</span>
            </button>
          </li>
          <li>
            <a
              href="#submenu2"
              data-bs-toggle="collapse"
              className="nav-link px-0 align-middle "
            >
              <i className="fs-4 bi-bootstrap"></i>{' '}
              <span className="ms-1 d-none d-sm-inline">ניהול חנות</span>
            </a>
            <ul
              className="collapse nav flex-column ms-1"
              id="submenu2"
              data-bs-parent="#menu"
            >
              <li className="w-100">
                <button className="nav-link px-0">
                  <span className="d-none d-sm-inline">הגדרות</span>
                </button>
              </li>
              <li>
                <button className="nav-link px-0">
                  <span className="d-none d-sm-inline">כלים</span>
                </button>
              </li>
            </ul>
          </li>
          <li>
            <a
              href="#submenu3"
              data-bs-toggle="collapse"
              className="nav-link px-0 align-middle"
            >
              <i className="fs-4 bi-grid"></i>{' '}
              <span className="ms-1 d-none d-sm-inline">מוצרים</span>{' '}
            </a>
            <ul
              className="collapse nav flex-column ms-1"
              id="submenu3"
              data-bs-parent="#menu"
            >
              <li className="w-100">
                <button
                  className="nav-link px-0"
                  onClick={() => setCurrentScreen('products')}
                >
                  <span className="d-none d-sm-inline">הוספת מוצר</span>{' '}
                </button>
              </li>
              <li className="w-100">
                <button
                  className="nav-link px-0"
                  onClick={() => setCurrentScreen('allproducts')}
                >
                  <span className="d-none d-sm-inline">ניהול מוצרים</span>{' '}
                </button>
              </li>
              <li>
                <button
                  className="nav-link px-0"
                  onClick={() => setCurrentScreen('categories')}
                >
                  <span className="d-none d-sm-inline">ניהול קטגוריות</span>
                </button>
              </li>
            </ul>
          </li>
          <li>
            <button className="nav-link px-0 align-middle">
              <i className="fs-4 bi-people"></i>{' '}
              <span className="ms-1 d-none d-sm-inline">לקוחות</span>{' '}
            </button>
          </li>
          <li>
            <button
              className="nav-link px-0 align-middle"
              onClick={() => setCurrentScreen('users')}
            >
              <i className="fs-4 bi-people"></i>{' '}
              <span className="ms-1 d-none d-sm-inline">ניהול משתמשים</span>{' '}
            </button>
          </li>
        </ul>
        <hr />
        <div className="dropdown pb-4">
          <a
            href="#"
            className="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
            id="dropdownUser1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              src="https://github.com/mdo.png"
              alt="hugenerd"
              width="30"
              height="30"
              className="rounded-circle"
            />
            <span className="d-none d-sm-inline mx-1">שלומי ביטן</span>
          </a>
          <ul
            className="dropdown-menu dropdown-menu-dark text-small shadow"
            aria-labelledby="dropdownUser1"
          >
            <li>
              <a className="dropdown-item" href="#">
                הגדרות
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                פרופיל
              </a>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li>
              <a className="dropdown-item" href="#">
                התנתק
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SideMenu;
