import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function Register() {
  const [isRegistered, setIsRegistered] = useState(null);
  const navigate = useNavigate();

  const handleRegistration = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);

    try {
      const response = await fetch('/api/users.php', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        setIsRegistered(true);
        setTimeout(() => {
          navigate('/login');
        }, 2000);
      } else {
        setIsRegistered(false);
      }
    } catch (error) {
      console.error('שגיאה בביצוע הבקשה:', error);
      setIsRegistered(false);
    }
  };

  return (
    <div className="registerBlock">
      <Helmet>
        <title>הרשמה לאתר</title>
        <meta name="description" content="הרשמה למועדון הלקוחות שלנו" />
      </Helmet>
      <div className="row">
        <div className="panel panel-primary">
          {isRegistered !== null ? (
            <div
              className={`alert ${
                isRegistered ? 'alert-success' : 'alert-danger'
              }`}
              role="alert"
            >
              {isRegistered
                ? 'נרשמת בהצלחה! מיד תועבר...'
                : 'ניסיון ההרשמה כשל, יש לנסות שוב!'}
            </div>
          ) : null}
          <div className="panel-body">
            <form onSubmit={handleRegistration}>
              <div className="form-group">
                <h2>פתיחת חשבון:</h2>
              </div>
              <div className=" form-group registerFormGrid">
                <div className="form-group">
                  <label className="control-label" htmlFor="firstName">
                    שם פרטי
                  </label>
                  <input
                    id="firstName"
                    name="FirstName"
                    type="text"
                    maxLength="50"
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <label className="control-label" htmlFor="lastName">
                    שם משפחה
                  </label>
                  <input
                    id="lastName"
                    name="LastName"
                    type="text"
                    maxLength="50"
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <label className="control-label" htmlFor="phone">
                    טלפון
                  </label>
                  <input
                    id="phone"
                    name="Phone"
                    type="tel"
                    maxLength="50"
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <label className="control-label" htmlFor="email">
                    מייל
                  </label>
                  <input
                    id="email"
                    type="email"
                    name="Email"
                    maxLength="50"
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <label className="control-label" htmlFor="Password">
                    סיסמא
                  </label>
                  <input
                    id="Password"
                    type="password"
                    name="Password"
                    maxLength="25"
                    className="form-control"
                    placeholder="יש לרשום סיסמא בת 8 תווים ויותר"
                    length="40"
                    required
                  />
                </div>
                {/* <div className="form-group">
                  <label className="control-label" htmlFor="signupPasswordagain">
                    אימות סיסמא
                  </label>
                  <input
                    id="signupPasswordagain"
                    type="password"
                    maxLength="25"
                    className="form-control"
                  />
                </div> */}
                <div className="form-group">
                  <label className="control-label" htmlFor="city">
                    עיר
                  </label>
                  <input
                    id="city"
                    name="City"
                    type="text"
                    maxLength="50"
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <label className="control-label" htmlFor="address">
                    כתובת מלאה
                  </label>
                  <input
                    id="address"
                    name="Address"
                    type="text"
                    maxLength="50"
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <label className="control-label"></label>
                  <input
                    name="Type"
                    type="hidden"
                    value="0"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="form-group">
                <br />
                <button
                  id="signupSubmit"
                  type="submit"
                  className="btn btn-primary"
                >
                  יצירת חשבון
                </button>
              </div>
              <br />
              <p className="form-group">
                על ידי יצירת חשבון, אתה מסכים לתנאי השימוש שלנו ולמדיניות
                הפרטיות שלנו.
              </p>
              <hr />
              <p>
                משתמש קיים? <Link to="/login">התחבר</Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
