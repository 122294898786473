import React, { useEffect, useState } from 'react';
import axios from 'axios';

function Users() {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 5;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/allusers.php`);
        const data = response.data;
        setUsers(data);
      } catch (err) {
        console.error('בעיה בקבלת משתמשים', err);
      }
    };
    fetchData();
  }, []);

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(users.length / usersPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div class="container">
      <link
        href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css"
        rel="stylesheet"
      />
      <h1>ניהול משתמשים</h1>
      <div class="row">
        <div class="col-lg-12">
          <div class="main-box clearfix">
            <div class="table-responsive">
              <table class="table user-list">
                <thead>
                  <tr>
                    <th>משתמש</th>
                    <th>נוצר</th>
                    <th class="text-center">סטטוס</th>
                    <th>טלפון</th>
                    <th>כתובת</th>
                    <th>אימייל</th>
                    <th>פעולות</th>
                  </tr>
                </thead>
                <tbody>
                  {currentUsers.map((user) => (
                    <tr key={user}>
                      <td>
                        <a href="#" class="user-link">
                          {user.FirstName} {user.LastName}
                        </a>
                        <span class="user-subhead">
                          {user.Type == 0 ? 'משתמש רגיל' : 'מנהל מערכת'}
                        </span>
                      </td>
                      <td>{user.created}</td>
                      <td class="text-center">
                        <span class="label label-default">
                          {user.Status == 0 ? 'לא פעיל' : 'פעיל'}
                        </span>
                      </td>
                      <td>
                        <span>{user.Phone}</span>
                      </td>
                      <td>
                        <span>
                          {user.Address}, {user.City}
                        </span>
                      </td>
                      <td>
                        <a href="mailto:`${user.Email}`">{user.Email}</a>
                      </td>
                      <td>
                        <a href="#" class="table-link">
                          <span class="fa-stack">
                            <i class="fa fa-square fa-stack-2x"></i>
                            <i class="fa fa-search-plus fa-stack-1x fa-inverse"></i>
                          </span>
                        </a>
                        <a href="#" class="table-link">
                          <span class="fa-stack">
                            <i class="fa fa-square fa-stack-2x"></i>
                            <i class="fa fa-pencil fa-stack-1x fa-inverse"></i>
                          </span>
                        </a>
                        <a href="#" class="table-link danger">
                          <span class="fa-stack">
                            <i class="fa fa-square fa-stack-2x"></i>
                            <i class="fa fa-trash-o fa-stack-1x fa-inverse"></i>
                          </span>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <ul className="pagination pull-right">
              {pageNumbers.map((number) => (
                <li
                  key={number}
                  className={
                    number === currentPage
                      ? 'btn btn-light active'
                      : 'btn btn-light'
                  }
                >
                  <button
                    className="btn btn-light"
                    onClick={() => setCurrentPage(number)}
                  >
                    {number}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Users;
