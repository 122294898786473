import React, { useState, useEffect} from "react";
import "../../css/wishlist.css";
import { Link } from "react-router-dom";
import 'animate.css';

const WishList = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "auto"
  });
  const [storedWishItems, setStoredWishItems] = useState([]);
  const [deletedProduct, setDeletedProduct] = useState(null);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("wishItems")) || [];
    setStoredWishItems(items);
  }, []);

  const removeItem = (index) => {
    const productToRemove = storedWishItems[index];
    setDeletedProduct(productToRemove);
    setShowConfirmationPopup(true);
  };

  const addToCartAndRemove = (product) => {
    const cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    cartItems.push(product);
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
  
    const updatedWishItems = [...storedWishItems];
    const deletedIndex = updatedWishItems.indexOf(product);
    updatedWishItems.splice(deletedIndex, 1);
  
    setStoredWishItems(updatedWishItems);
    localStorage.setItem("wishItems", JSON.stringify(updatedWishItems));
  };

  const removeConfirmedItem = () => {
    const updatedWishItems = [...storedWishItems];
    const deletedIndex = updatedWishItems.indexOf(deletedProduct);
    updatedWishItems.splice(deletedIndex, 1);
    
    setStoredWishItems(updatedWishItems);
    localStorage.setItem("wishItems", JSON.stringify(updatedWishItems));
  
    setDeletedProduct(null); 
    setShowConfirmationPopup(false);
  };

  return (
    <div className="cartPage container">
      <h1>המוצרים שאהבתי</h1>
      יש לך {storedWishItems.length} פריטים שמורים
      <div className="row">
     
              <div className="col-md-12">
          <div className="table-wishlist">
            {storedWishItems.map((item, index) => (
               <div className="row border-top border-bottom" key={index}>
               <div className="row main align-items-center">
               
                 <div className="col-3">
                 <Link className="linkPro" to={`/products/${item.Slug}`} >
                   <img
                     className="img-fluid imgitem"
                     src={`../upload/images/products/${item.Image}`}
                   />
                   </Link>
                 </div>
                 <div className="col">
                 <Link className="linkPro" to={`/products/${item.Slug}`} >
                   <div className="row text-muted productName">{item.ProductName}</div>
                   </Link>
                   <div className="row">{item.Brand}</div>
                 </div>
                 
                 <div className="col-3 d-flex">
                 <button
                     className="btn btn-warning btn-sm wishadd"
                     onClick={() => addToCartAndRemove(item)}
                   >
                     הוספה לסל
                   </button>
                 </div>
                 <div className="col">₪{item.SalePrice || item.Price}</div>
                 <div className="col-1">
                   <button
                     className="btn btn-danger btn-sm"
                     onClick={() => removeItem(index)}
                   >
                     &#10005;
                   </button>
                 </div>
               </div>
             </div>
            ))}
          </div>
        </div>
      </div>
      {showConfirmationPopup && (
  <div className="popup">
    <div className="popup-content animate__animated animate__fadeIn">
      <p>
        האם אתה בטוח שברצונך למחוק את המוצר {deletedProduct.ProductName}?
      </p>
      <button
        className="btn btn-danger"
        onClick={() => {
          removeConfirmedItem();
          setShowConfirmationPopup(false);
        }}
      >
        אישור
      </button>
      <button
        className="btn btn-secondary"
        onClick={() => setShowConfirmationPopup(false)}
      >
        ביטול
      </button>
    </div>
  </div>
)}
    </div>
  );
};

export default WishList;
