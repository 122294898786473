import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';

const Head = () => {

    const [pixels, setPixels] = useState([]);
    const [settings, setSettings] = useState([]);

    useEffect(() => {
        const getPixels = async () => {
            try {
                const res = await axios.get('./api/pixels.php');
                const data = res.data;
                setPixels(data[0]);
            } catch (error) {
                console.log(error);
            }
        };
        getPixels();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(
              `./api/settings.php`
            );
            const data = response.data[0];
            setSettings(data);
          } catch (err) {
            console.error('בעיה בקבלת משלוחים', err);
          }
        };
        fetchData();
      }, []);

    return (

        <Helmet>
            <title>{settings.sitename}</title>
            <meta
              name="description"
              content={settings.description}
            />
            <meta property="og:locale" content="he_IL" />
            <div>{pixels.head}</div>
        </Helmet>

    );
};

export default Head;
