import React, { useEffect, useState } from 'react';
import axios from 'axios';

function AllProduct() {
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const productPerPage = 10;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/products.php`);
        const data = response.data;
        setProducts(data);
      } catch (err) {
        console.error('בעיה בקבלת משתמשים', err);
      }
    };
    fetchData();
  }, []);
  
  const indexOfLastUser = currentPage * productPerPage;
  const indexOfFirstUser = indexOfLastUser - productPerPage;
  const currentProducts = products.slice(indexOfFirstUser, indexOfLastUser);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(products.length / productPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div class="container">
    <link
      href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css"
      rel="stylesheet"
    />
    <h1>ניהול מוצרים</h1>
    <div class="row">
      <div class="col-lg-12">
        <div class="main-box clearfix">
          <div class="table-responsive">
            <table class="table user-list">
              <thead>
                <tr>
                <th>תמונה</th>
                  <th>שם מוצר</th>
                  <th>מחיר</th>
                  <th>מחיר מבצע</th>
                  <th>מלאי</th>
                  <th>מותג</th>
                  <th>ביקורות</th>
                  <th>פעולות</th>
                </tr>
              </thead>
              <tbody>
                {currentProducts.map((product) => (
                  <tr key={product}>
                    <td>
                        <img src={`../../upload/images/products/${product.Image}`} />
                    </td>
                    <td>
                      <a href="#" class="user-link">
                        {product.ProductName}
                      </a>
                      {/* <span class="user-subhead">
                        {user.Type == 0 ? 'משתמש רגיל' : 'מנהל מערכת'}
                      </span> */}
                    </td>
                    <td>{product.Price}</td>
                    <td class="text-center">
                      <span class="label label-default">
                        {product.SalePrice}
                      </span>
                    </td>
                    <td>
                      <span>{product.Stock}</span>
                    </td>
                    <td>
                      <span>
                        {product.Brand}
                      </span>
                    </td>
                    <td>
                    <span>
                        {product.Rating}
                      </span>
                    </td>
                    <td>
                      <a href={`/products/${product.Slug}`} class="table-link">
                        <span class="fa-stack">
                          <i class="fa fa-square fa-stack-2x"></i>
                          <i class="fa fa-search-plus fa-stack-1x fa-inverse"></i>
                        </span>
                      </a>
                      <a href="#" class="table-link">
                        <span class="fa-stack">
                          <i class="fa fa-square fa-stack-2x"></i>
                          <i class="fa fa-pencil fa-stack-1x fa-inverse"></i>
                        </span>
                      </a>
                      <a href="#" class="table-link danger">
                        <span class="fa-stack">
                          <i class="fa fa-square fa-stack-2x"></i>
                          <i class="fa fa-trash-o fa-stack-1x fa-inverse"></i>
                        </span>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <ul className="pagination pull-right">
            {pageNumbers.map((number) => (
              <li
                key={number}
                className={
                  number === currentPage
                    ? 'btn btn-light active'
                    : 'btn btn-light'
                }
              >
                <button
                  className="btn btn-light"
                  onClick={() => setCurrentPage(number)}
                >
                  {number}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  </div>
  );
}

export default AllProduct;
