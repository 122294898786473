import React from "react";

function ContactUs() {
  return (
    <section className="mb-4 contactUs">
      <h2 className="h1-responsive font-weight-bold text-center my-4">
        צרו איתנו קשר
      </h2>

      <p className="text-center w-responsive mx-auto mb-5">
      יש לך שאלות? אנא אל תהסס לפנות אלינו
         באופן ישיר. הצוות שלנו יחזור אליכם תוך מספר שעות
         ונעזור לך.
      </p>

      <div className="row formcontact" >
        <div className="col-md-6 mb-md-0 mb-5">
          <form
            id="contact-form"
            name="contact-form"
            action="mail.php"
            method="POST"
          >
            <div className="row">
              <div className="col-md-6">
                <div className="md-form mb-0">
                <label htmlFor="name" className="">
                    השם המלא שלך
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="form-control"
                  />

                </div>
              </div>

              <div className="col-md-6">
                <div className="md-form mb-0">
                <label htmlFor="email" className="">
                    המייל שלך
                  </label>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    className="form-control"
                  />

                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="md-form mb-0">
                <label htmlFor="subject" className="">
                    נושא הפנייה
                  </label>
                  <input
                    type="text"
                    id="subject"
                    name="subject"
                    className="form-control"
                  />

                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="md-form">
                <label htmlFor="message">תוכן הפנייה</label>
                  <textarea
                    type="text"
                    id="message"
                    name="message"
                    rows="4"
                    className="form-control md-textarea"
                  ></textarea>

                </div>
              </div>
            </div>
          </form>

          <div className="btnSubmit">
            <a
              className="btn btn-primary"
              onClick="document.getElementById('contact-form').submit();"
            >
              שליחה
            </a>
          </div>
          <div className="status"></div>
        </div>

        <div className="col-md-3 text-center">
          <ul className="mb-0">
            <li>
              <i className="fas fa-map-marker-alt fa-2x"></i>
              <p>בן יהודה 13ת שדרות</p>
            </li>

            <li>
              <i className="fas fa-phone mt-4 fa-2x"></i>
              <p>0542052019</p>
            </li>

            <li>
              <i className="fas fa-envelope mt-4 fa-2x"></i>
              <p>contact@mdbootstrap.com</p>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
