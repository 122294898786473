import React, { useEffect, useState } from 'react';
import '../css/header.css';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

const Header = () => {
  const [loggedInUsername, setLoggedInUsername] = useState('');
  const navigate = useNavigate();

  const [cartItems, setCartItems] = useState([]);
  const [wishItems, setWishItems] = useState([]);
  const [categories, setCategory] = useState([]);

  useEffect(() => {
    const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    setCartItems(storedCartItems);
    const storedWishItems = JSON.parse(localStorage.getItem('wishItems')) || [];
    setWishItems(storedWishItems);

    const storedUser = localStorage.getItem('loggedInUser');
    if (storedUser) {
      setLoggedInUsername(storedUser);
    }
  }, [cartItems, wishItems, loggedInUsername]);

  useEffect(() => {
    getCategory();
 
  }, []);

  const getCategory = async () => {
    try {
      const res = await axios.get('./api/categories.php');
      const data = res.data;
      setCategory(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleLogout = () => {
    setLoggedInUsername('');
    localStorage.removeItem('loggedInUser');
    navigate('/login');
  };

  return (

      <section className="secHeader">
        <div className="infoHeader">
          <div>
            <ul>
              <li>
                <Link to="/">דף הבית</Link>
              </li>
              <li>
                <Link to="/pages/aboutus">אודותינו</Link>
              </li>
              <li>
                <Link to="/pages/contact">יצירת קשר</Link>
              </li>
            </ul>
          </div>
          <div>
            <ul>
              {loggedInUsername ? (
                <>
                  <li>שלום, {loggedInUsername}!</li>
                  <li>
                    <a
                      href="#"
                      className="link-danger link-underline-opacity-25"
                      onClick={handleLogout}
                    >
                      התנתק
                    </a>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <Link to="/login">כניסה</Link>
                  </li>
                  <li>
                    <Link to="/register">הרשמה</Link>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
        <div className="mainHeader">
          <div className="logo">
            <Link to="/">
              <img src="../logo.png" alt="" />
            </Link>
          </div>
          <div className="searchBlock">
            <input type="search" />
          </div>
          <Link to="/wishlist" className="wishlistmenuM">
            <div className="wishlistmenu">
              <span className="cartItems wishlist">{wishItems.length}</span>
              <i className="bi bi-bag-heart header"></i>
              מוצרים שאהבתי
            </div>
          </Link>
          <Link to="/cart" className="cartmenuM">
            <div className="cartmenu">
              <span className="cartItems">{cartItems.length}</span>
              <i className="bi bi-bag header"></i>
              עגלת קניות
            </div>
          </Link>
        </div>
        <div className="storeHeader">
          <div className="scrollmenuM">
            <ul>
              {categories.map((category) => (
                <li key={category.id}>
                  <Link
                    to={`/categories/${category.Category_Name.replace(
                      /\s+/g,
                      '_'
                    )}`}
                  >
                    <i className={`${category.icon}`}></i>
                    {category.Category_Name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
  );
};

export default Header;
