import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../css/products.css';
import { Link } from 'react-router-dom';

const Products = ({ query }) => {
  const [products, setProducts] = useState([]);
  const [isInWishList, setIsInWishList] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(query);
        const data = response.data;
        setProducts(data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [query]);

  const addToWishList = (product) => {
    const wishItems = JSON.parse(localStorage.getItem('wishItems')) || [];
    wishItems.push(product);
    localStorage.setItem('wishItems', JSON.stringify(wishItems));
    setIsInWishList(true);
    checkIfInWishList(product);
  };

  const checkIfInWishList = (product) => {
    const wishItems = JSON.parse(localStorage.getItem('wishItems')) || [];
    const isInWishlist = wishItems.some(
      (item) => item.ProductName === product.ProductName
    );
    setIsInWishList(isInWishlist);
  };

  return (
    <div className="container productCard">
      <div className="row productGridMobile">
        {products.map((product) => (
          <div className="col-md-6 col-lg-3" key={product._id}>
            <Link to={`/products/${product.Slug}`}>
              <div className="card">
                <div className="image-container">
                  <div className="first">
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="discount">{`במלאי: ${product.Stock}`}</span>
                      <button
                        className={`heart ${isInWishList ? 'check' : ''}`}
                        disabled={isInWishList}
                        onClick={() => addToWishList(product)}
                      >
                        <i
                          className={`bi bi-heart${
                            isInWishList ? '-fill check' : ''
                          }`}
                        ></i>
                      </button>
                    </div>
                  </div>
                  <div className="productImg">
                    <img
                      src={`/upload/images/products/${product.Image}`}
                      alt={product.ProductName}
                      className="thumbnail-image"
                    />
                  </div>
                </div>

                <div className="product-detail-container p-2">
                  <div className="align-items-center">
                    <h5 className="product-name">{product.ProductName}</h5>
                    <div className="prices">
                      <span className="new-price">₪{product.SalePrice}</span>
                      <small className="old-price text-right">
                        ₪{product.Price}
                      </small>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-center pt-1">
                    <div>
                      <i className="bi bi-star"></i>
                      <span className="rating-number">4.8</span>
                    </div>
                    <span className="buy">קנה עכשיו</span>
                  </div>
                </div>
              </div>
            </Link>

            {/* <div className="mt-3">
              <div className="card voutchers">
                <div className="voutcher-divider">
                  <div className="voutcher-left text-center">
                    <span className="voutcher-name">קוד קופון:</span>
                    <h5 className="voutcher-code">#MONHPY</h5>
                  </div>
                  <div className="voutcher-right text-center border-left">
                    <h5 className="discount-percent">20%</h5>
                    <span className="off">הנחה</span>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Products;
