import React, { useEffect, useState } from 'react';
import '../../css/cart.css';
import axios from 'axios';
import 'animate.css';
import { Link } from 'react-router-dom';

function generateRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const Cart = () => {
  const [orderNumber, setOrderNumber] = useState(
    generateRandomNumber(1000, 9999)
  );

  const [deletedProduct, setDeletedProduct] = useState(null);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [shippments, setShippments] = useState([]);
  const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];

  const [shipping, setShipping] = useState(' ');
  const [quantities, setQuantities] = useState(storedCartItems.map(() => 1));

  const [btnText, setBtnText] = useState('יש לבחור שיטת משלוח');

  const priceShipping = shipping
    .split('')
    .filter((character) => !isNaN(character))
    .join('');

  const handleShippingChange = (event) => {
    setShipping(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/shipments.php`);
        const data = response.data;
        setShippments(data);
      } catch (err) {
        console.error('בעיה בקבלת משלוחים', err);
      }
    };
    fetchData();
  }, []);

  const decreaseQuantity = (index) => {
    if (quantities[index] > 1) {
      const updatedQuantities = [...quantities];
      updatedQuantities[index] = updatedQuantities[index] - 1;
      setQuantities(updatedQuantities);
    }
  };

  const increaseQuantity = (index) => {
    const updatedQuantities = [...quantities];
    updatedQuantities[index] = updatedQuantities[index] + 1;
    setQuantities(updatedQuantities);
  };

  const removeItem = (index) => {
    const productToRemove = storedCartItems[index];
    setDeletedProduct(productToRemove.id);
    setShowConfirmationPopup(true);
  };

  const removeConfirmedItem = () => {
    const updatedCartItems = storedCartItems.filter(
      (item) => item.id !== deletedProduct
    );
    localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));

    const updatedQuantities = quantities.filter(
      (_, index) => index !== storedCartItems.indexOf(deletedProduct)
    );
    setQuantities(updatedQuantities);

    setDeletedProduct(null);
    setShowConfirmationPopup(false);
  };

  const calculateTotalPrice = () => {
    let totalPrice = 0;

    for (let i = 0; i < storedCartItems.length; i++) {
      const item = storedCartItems[i];
      const price = item.SalePrice || item.Price;
      totalPrice += price * quantities[i];
    }

    return totalPrice;
  };

  const totalPrice = calculateTotalPrice() + parseInt(priceShipping);
  console.log(totalPrice);

  return (
    <div className="cartPage container">
      <h1>סל קניות</h1>
      <div className="row1">
        <div className="col-md-12 cart">
          <div className="title">
            <div className="row">
              <div className="col align-self-center text-right text-muted">
                יש לך {storedCartItems.length} פריטים בסל הקניות
              </div>
            </div>
          </div>
          {storedCartItems.map((item, index) => (
            <div className="row border-top border-bottom" key={index}>
              <div className="row main align-items-center">
                <div className="col-3">
                  <img
                    className="img-fluid imgitem"
                    src={`../upload/images/products/${item.Image}`}
                  />
                </div>
                <div className="col">
                  <div className="row text-muted productName">
                    {item.ProductName}
                  </div>
                  <div className="row">{item.Brand}</div>
                </div>
                <div className="col d-flex">
                  <button
                    className="btn btn-light btn-sm"
                    onClick={() => decreaseQuantity(index)}
                  >
                    -
                  </button>
                  <span className="quantity">{quantities[index]}</span>
                  <button
                    className="btn btn-light btn-sm"
                    onClick={() => increaseQuantity(index)}
                  >
                    +
                  </button>
                </div>
                <div className="col">₪{item.SalePrice || item.Price}</div>
                <div className="col-1">
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => removeItem(index)}
                  >
                    &#10005;
                  </button>
                </div>
              </div>
            </div>
          ))}

          <div className="back-to-shop">
            <br />
            <a href="#" className="btn btn-dark btn-sm">
              חזרה לחנות
            </a>
          </div>
        </div>
        {storedCartItems.length > 0 && (
          <div className="col-md-6 summary">
            <div className="row">
              <h3>סיכום הזמנה</h3>
              <div className="col text-success">
                סך הכל {storedCartItems.length} פריטים
              </div>
              <div className="col text-right ">₪ {calculateTotalPrice()}</div>
            </div>
            <br />
            <form>
              <p className="fw-bold">שיטת משלוח:</p>
              <select
                className="form-select"
                size="4"
                aria-label="size 3 select example"
                onChange={handleShippingChange}
                value={shipping}
                required
              >
                <option selected>בחרו שיטת משלוח:</option>
                {shippments.map((shippment) => (
                  <option key={shippment.id} className="text-muted">
                    {`${shippment.method} - ₪${shippment.price}`}
                  </option>
                ))}
              </select>
              <br />
              <p className="fw-bold">קוד קופון:</p>
              <div className="row">
                <input
                  className="col form-control"
                  id="code"
                  placeholder="הזן את קוד הקופון כאן"
                />
                <button className="col btn btn-outline-warning btn-sm">
                  החל קופון
                </button>
              </div>
            </form>
            <br />
            <div className="row">
              <div className="col-5 ">שיטת משלוח:</div>
              <div className="col-7 text-right">{shipping}</div>
            </div>
            <div className="row">
              <div className="col-5 fw-bold">סך הכל לתשלום:</div>
              <div className="col-7 text-right fw-bold">
                {isNaN(totalPrice) ? btnText : `₪${totalPrice}`}
              </div>
            </div>
            <br />
            <Link
              to={`/checkout?totalPrice=${totalPrice}&OrderNumber=${orderNumber}`}
              className={isNaN(totalPrice) ? 'disabledBtn' : ''}
            >
              <button
                className="btn btn-warning w-100 p-3 bold"
                disabled={isNaN(totalPrice)}
              >
                {isNaN(totalPrice) ? btnText : `מעבר לתשלום`}
              </button>
            </Link>
          </div>
        )}
      </div>
      {showConfirmationPopup && (
        <div className="popup">
          <div className="popup-content animate__animated animate__fadeIn">
            <p>
              האם אתה בטוח שברצונך למחוק את המוצר {deletedProduct.ProductName}?
            </p>
            <button
              className="btn btn-danger"
              onClick={() => {
                removeConfirmedItem();
                setShowConfirmationPopup(false);
              }}
            >
              אישור
            </button>
            <button
              className="btn btn-secondary"
              onClick={() => setShowConfirmationPopup(false)}
            >
              ביטול
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Cart;
