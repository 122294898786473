import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import '../../css/cart.css';
import 'animate.css';
import CreditCard from './comps/creditcard';
import PayPal from './comps/paypal';
import Shipping from './comps/shipping';

const CheckOut = () => {

  function scrollToTestDiv(){
    setTimeout(() => {
      const divElement = document.getElementById('creditcard');
      divElement.scrollIntoView({ behavior: 'smooth' });
    }, 300);

  }

  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'auto',
  });

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const totalPrice = searchParams.get('totalPrice');
  const orderNumber = searchParams.get('OrderNumber');
  const [paymentMethod, setPaymentMethod] = useState('');
  const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];

  const handlePaymentMethodChange = (method) => {
    setPaymentMethod(method);
  };

  return (
    <div className="checkoutPage">
      <p>מספר הזמנה: {orderNumber}</p>
      <Shipping ordernumber={orderNumber} total={totalPrice}/>

      {/* <ul>
        {storedCartItems.map((item) => (
          <li key={item.id}>{item.ProductName}</li>
        ))}
      </ul> */}
      <h4 className="text-center">סך הכל לתשלום: ₪{totalPrice}</h4>
      <div>
        <h5>בחרו שיטת תשלום:</h5>
        <input
          type="radio"
          class="btn-check"
          name="options-base"
          id="option5"
          autocomplete="off"
          checked={paymentMethod === 'creditCard'}
          onChange={() => handlePaymentMethodChange('creditCard')}
        />
        <label className="btn btn-dark mb-2 w-100 p-3" for="option5" onClick={scrollToTestDiv}>
          <i className="bi bi-credit-card"></i> כרטיס אשראי
        </label>

        <PayPal amount={totalPrice} />
      </div>
      <br/>
      {paymentMethod === 'creditCard' && <CreditCard />}
    </div>
  );
};

export default CheckOut;
