import React from 'react';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const PayPal = ({ amount }) => {
    console.log(amount);
    const initialOptions = {
        clientId: "ARCIdIAz7rJiAqVu2Ya2MIta9CM5BSeNTjDKcvq-HeBN4yXxiOSFUfZwc4Qs7B266EXJFliRtaTYTnKg",
        currency: "ILS",
        intent: "capture",
    };

    return (
        <div>
            <PayPalScriptProvider options={initialOptions}>
                <PayPalButtons
                    style={{ layout: "horizontal" }}
                    createOrder={(data, actions) => {
                        return actions.order.create({
                            purchase_units: [
                                {
                                    amount: {
                                        value: 0.1,
                                    },
                                },
                            ],
                        });
                    }}
                />
            </PayPalScriptProvider>
        </div>
    );
};

export default PayPal;
