import React from 'react';
import Products from '../src/comps/products';
import Header from '../src/comps/header';
import Footer from '../src/comps/footer';
import NewsLetter from '../src/comps/newsletter';
import Register from '../src/pages/register';
import Login from '../src/pages/login';
import ProductPage from '../src/pages/store/productpage';
import CategoryPage from '../src/pages/store/category';
import Cart from '../src/pages/store/cart';
import WishList from '../src/pages/store/wishlist';
import AboutUs from '../src/pages/aboutus';
import ContactUs from '../src/pages/contact';
import ImageCarousel from '../src/comps/carousel';
import DeshboardAdmin from '../src/pages/admin/deshboard';
import '../src/css/main.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Head from './comps/head';
import CheckOut from './pages/store/checkout';

const HomePage = () => {
  return (
    <React.Fragment>
      <ImageCarousel />
      <div className="container">
        <h1>החדשים באתר:</h1>
        <Products query={'/api/products.php?limit=8'} />
      </div>
    </React.Fragment>
  );
};

const Layout = ({ children }) => {
  return (
    <>
      <Head />
      <Header />
      <main>{children}</main>
      <NewsLetter />
      <Footer />
      
    </>
  );
};

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Layout>
              <HomePage />
            </Layout>
          }
        />
        <Route path="/admin" element={<DeshboardAdmin />} />
        <Route
          path="/login"
          element={
            <Layout>
              <Login />
            </Layout>
          }
        />
        <Route
          path="/register"
          element={
            <Layout>
              <Register />
            </Layout>
          }
        />
        <Route
          path="/cart"
          element={
            <Layout>
              <Cart />
            </Layout>
          }
        />
        <Route
          path="/checkout"
          element={
            <Layout>
              <CheckOut />
            </Layout>
          }
        />
        <Route
          path="/wishlist"
          element={
            <Layout>
              <WishList />
            </Layout>
          }
        />
        <Route
          path="/products/:slug"
          element={
            <Layout>
              <ProductPage />
            </Layout>
          }
        />

        <Route
          path="/categories/:slug"
          element={
            <Layout>
              <CategoryPage />
            </Layout>
          }
        />
        <Route
          path="/pages/aboutus"
          element={
            <Layout>
              <AboutUs />
            </Layout>
          }
        />
        <Route
          path="/pages/contact"
          element={
            <Layout>
              <ContactUs />
            </Layout>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
