import React from "react";

const NewsLetter = () => {
    return (
        <section className="newsletter">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="content">
                            <h2>חשוב לנו שתישארו מעודכנים!</h2>
                            <p>מבצעים, קופונים ומתנות מפנקות, הרשמו לניוזלטר שלנו ונהיה בקשר</p>
                            <div className="input-group">
                                <input type="email" className="form-control" placeholder="כתובת המייל שלכם" />
                                <span className="input-group-btn">
                                    <button className="btn" type="submit">שילחו אליי!</button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default NewsLetter;
