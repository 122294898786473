import React, { useEffect, useState } from 'react';
import axios from 'axios';

function Categories({ setCurrentScreen }) {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 10;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/categories.php`);
        const data = response.data;
        setUsers(data);
      } catch (err) {
        console.error('בעיה בקבלת שם הקטגוריה', err);
      }
    };
    fetchData();
  }, []);

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(users.length / usersPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div class="container">
      <link
        href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css"
        rel="stylesheet"
      />
      <h1>ניהול קטגוריות</h1>

      <button
        className="btn btn-primary"
        onClick={() => setCurrentScreen('addcategories')}
      >
        הוסף קטגוריה
      </button>

      <div class="row">
        <div class="col-lg-12">
          <div class="main-box clearfix">
            <div class="table-responsive">
              <table class="table user-list">
                <thead>
                  <tr>
                    <th>שם קטגוריה</th>
                    <th>אייקון</th>
                    <th>פעולות</th>
                  </tr>
                </thead>
                <tbody>
                  {currentUsers.map((user) => (
                    <tr key={user}>
                      <td>
                        <span class="user-link">{user.Category_Name}</span>
                      </td>
                      <td className="icon">
                        {user.icon && <i className={user.icon}></i>}
                      </td>
                      <td>
                        <a href="#" class="table-link">
                          <span class="fa-stack">
                            <i class="fa fa-square fa-stack-2x"></i>
                            <i class="fa fa-search-plus fa-stack-1x fa-inverse"></i>
                          </span>
                        </a>
                        <a class="table-link">
                          <span class="fa-stack">
                            <i class="fa fa-square fa-stack-2x"></i>
                            <i
                              class="fa fa-pencil fa-stack-1x fa-inverse"
                              onClick={() =>
                                setCurrentScreen('editcategory', user.id)
                              }
                            ></i>
                          </span>
                        </a>
                        <a href="#" class="table-link danger">
                          <span class="fa-stack">
                            <i class="fa fa-square fa-stack-2x"></i>
                            <i class="fa fa-trash-o fa-stack-1x fa-inverse"></i>
                          </span>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <ul className="pagination pull-right">
              {pageNumbers.map((number) => (
                <li
                  key={number}
                  className={
                    number === currentPage
                      ? 'btn btn-light active'
                      : 'btn btn-light'
                  }
                >
                  <button
                    className="btn btn-light"
                    onClick={() => setCurrentPage(number)}
                  >
                    {number}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Categories;
