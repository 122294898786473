import React, { useState } from "react";
import "react-credit-cards-2/dist/es/styles-compiled.css";

const Shipping = ({ ordernumber, total }) => {
  console.log(ordernumber);
  console.log(total);
  return (
    <div>
      <div className="row">
        <div className="panel panel-primary">
          <div className="panel-body">
            <form method="POST" action="/api/orders.php" role="form">
              <div className="form-group">
                <h2>כתובת למשלוח:</h2>
              </div>
              <div className=" form-group registerFormGrid">
                <div className="form-group">
                  <label className="control-label" htmlFor="firstName">
                    שם פרטי
                  </label>
                  <input
                    id="firstName"
                    name="firstName"
                    type="text"
                    maxLength="50"
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label className="control-label" htmlFor="lastName">
                    שם משפחה
                  </label>
                  <input
                    id="lastName"
                    name="lastName"
                    type="text"
                    maxLength="50"
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label className="control-label" htmlFor="phone">
                    טלפון
                  </label>
                  <input
                    id="phone"
                    name="phone"
                    type="tel"
                    maxLength="50"
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label className="control-label" htmlFor="email">
                    מייל
                  </label>
                  <input
                    id="email"
                    type="email"
                    name="email"
                    maxLength="50"
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label className="control-label" htmlFor="Password">
                    עיר
                  </label>
                  <input
                    id="city"
                    type="text"
                    name="city"
                    maxLength="25"
                    className="form-control"
                    placeholder=""
                    length="40"
                  />
                </div>
                <div className="form-group">
                  <label className="control-label" htmlFor="city">
                    כתובת מלאה
                  </label>
                  <input
                    id="address"
                    name="address"
                    type="text"
                    maxLength="50"
                    className="form-control"
                  />
                  <input name="ordernumber" type="hidden" value={ordernumber}/>
                  <input name="total" type="hidden" value={total}/>
                </div>
              </div>
              <br />
              <button type="submit">test</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shipping;
