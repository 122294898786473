import React from 'react';

function AddCategories() {
  return (
    <div class="container">
      <link
        href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css"
        rel="stylesheet"
      />
      <h1>הוספת קטגוריה</h1>
      <form
        method="POST"
        action="/api/categories.php"
        enctype="multipart/form-data"
      >
        <div className="mb-3">
          <label className="form-label">שם הקטגוריה</label>
          <input type="text" className="form-control" name="name" />
        </div>
        <div className="mb-3">
          <label className="form-label">אייקון קטגוריה</label>
          <div className="blockquote-footer">
            אייקון הקטגוריה נלקח מחבילת האייקון של Bootstrap
          </div>
          <a href="https://icons.getbootstrap.com/">קישור לסיפירה</a>
          <input type="text" className="form-control" name="icon" />
        </div>
        <button type="submit" className="btn btn-primary">
          הוספת קטגוריה
        </button>
      </form>
    </div>
  );
}

export default AddCategories;
