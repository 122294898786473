import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../../comps/loading";
import axios from "axios";
import Products from "../../comps/products";
import { Helmet } from "react-helmet";

function CategoryPage() {
  const params = useParams();
  const { slug } = params;

  const [category, setCategory] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/categories.php?slug=${slug}`);
        const data = response.data;
        setCategory(data);
      } catch (err) {
        console.error("בעיה בקבלת שם הקטגוריה", err);
      }
    };
    fetchData();
  }, [slug]);

  return (
    <>
      <div className="category_back" style={style}>
        {category.length > 0 ? (
          <>
            <Helmet>
              <title>{category[0].Category_Name}</title>
              <meta name="description" content={`${category[0].Category_Name}`} />
            </Helmet>
            <h1>{category[0].Category_Name}</h1>
          </>
        ) : (
          <Loading />
        )}
      </div>
      <div className="categorypage">
        <Products query={`/api/products.php?category=${slug}`} />
      </div>
    </>
  );
}

export default CategoryPage;

const style = {
  backgroundImage: `url(../images/category.jpg)`,
};
