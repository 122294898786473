import React, { useState } from 'react';
import axios from 'axios';
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css';

const CreditCard = () => {
  const [state, setState] = useState({
    number: '',
    expiry: '',
    cvc: '',
    name: '',
    focus: '',
  });

  const handleInputChange = (evt) => {
    const { name, value } = evt.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleInputFocus = (evt) => {
    setState((prev) => ({ ...prev, focus: evt.target.name }));
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();

    const paymentDetails = {
      testMode: true,
      groupPrivateToken: '80d75f51-1ca1-41a8-a698-8183d68499c6', // Replace with actual token
      maxPayments: 10,
      creditFromPayment: 100,
      hideItemList: true,
      createToken: false,
      // ...other payment details
    };

    try {
      const response = await axios.post(
        paymentDetails.testMode
          ? 'https://testicredit.rivhit.co.il/API/PaymentPageRequest.svc/GetUrl'
          : 'https://icredit.rivhit.co.il/API/PaymentPageRequest.svc/GetUrl',
        {
          GroupPrivateToken: paymentDetails.groupPrivateToken,
          MaxPayments: paymentDetails.maxPayments,
          CreditFromPayment: paymentDetails.creditFromPayment,
          HideItemList: paymentDetails.hideItemList,
          CreateToken: paymentDetails.createToken,
          // ...other parameters
        }
      );

      const paymentPageUrl = response.data.PaymentPageUrl;
      // Redirect to the payment page
      window.location.href = paymentPageUrl;
    } catch (error) {
      console.error('Error sending payment request:', error);
      // Handle error as needed
    }
  };

  const VerifyResponse = (saleId, privateGroupToken, amount) => {
    // Implement the verification logic here
    // You can use this function to verify the payment status
    // and handle the IPN notifications
  };

  return (
    <div id='creditcard'>
      <Cards
        number={state.number}
        expiry={state.expiry}
        cvc={state.cvc}
        name={state.name}
        focused={state.focus}
      />
      <form className="paymentForm" onSubmit={handleSubmit} method="POST">
        <div className="form-group">
          <small>שם מחזיק הכרטיס:</small>
          <input
            type="text"
            name="name"
            className="form-control"
            placeholder="שם מחזיק הכרטיס"
            pattern="[a-z A-Z-]+"
            required
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>
        <div className="form-group">
          <small>מספר כרטיס אשראי:</small>
          <input
            type="tel"
            name="number"
            className="form-control"
            placeholder="מספר כרטיס"
            pattern="[\d| ]{16,22}"
            maxLength="19"
            required
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>
        <div className="row">
          <div className="form-group col">
            <small>תוקף:</small>
            <input
              type="tel"
              name="expiry"
              className="form-control"
              placeholder="MM/YY"
              pattern="\d\d/\d\d"
              required
              onChange={handleInputChange}
              onFocus={handleInputFocus}
            />
          </div>
          <div className="form-group col">
            <small>CVC:</small>
            <input
              type="tel"
              name="cvc"
              className="form-control"
              placeholder="CVC"
              pattern="\d{3}"
              required
              onChange={handleInputChange}
              onFocus={handleInputFocus}
            />
          </div>
        </div>
        <div className="form-actions">
          <br />
          <button type="submit" className="btn btn-warning w-100 p-3 bold">
            ביצוע הזמנה
          </button>
        </div>
      </form>
      <p className="text-center icredittext">
        האתר אינו חשוף לפרטי כרטיס האשראי שלך והם אינם נשמרים באתר. סליקות
        כרטיסי אשראי נעשות על ידי חברת ICREDIT הישראלית המוסמכת לתקן אבטחת מידע
        PCI: DSS Level 1 , תקן האבטחה הגבוה ביותר בעולם.
        <br />
        כל העברה של מספר כרטיס אשראי באתר נעשית באופן מוצפן ומאובטח.
      </p>
    </div>
  );
};

export default CreditCard;
