import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Loading from '../../comps/loading';
import RelatedProducts from './relatedproducts';
import { Link, useNavigate } from 'react-router-dom';
import 'animate.css';
import '../../css/productpage.css';
import { Helmet } from 'react-helmet';

function ProductPage() {
  const navigate = useNavigate();

  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'auto',
  });
  const params = useParams();
  const { slug } = params;

  const [product, setProduct] = useState([]);
  const pageTitle = product.length > 0 ? product[0].ProductName : 'עמוד מוצר';
  const pageDescription =
    product.length > 0 ? product[0].Description : 'תיאור  מוצר';
  const pageImage = product.length > 0 ? product[0].Image : 'תמונת  מוצר';
  const [shortext, setShortext] = useState('');
  const [image, setImage] = useState(null);

  const [isInWishList, setIsInWishList] = useState(false);
  const [textPopUp, setTextPopUp] = useState('');
  const [listType, setlistType] = useState('');
  const [linkPopUp, setLinkPopUp] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  const addToCart = (product) => {
    const cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    cartItems.push(product);
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
    setShowPopup(true);
    setTextPopUp('התווסף לעגלת הקניות שלך!');
    setlistType('עגלת הקניות');
    setLinkPopUp('/cart');
  };

  const buyNow = (product) => {
    const cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    cartItems.push(product);
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
    navigate('/cart');
  };

  const addToWishList = (product) => {
    const wishItems = JSON.parse(localStorage.getItem('wishItems')) || [];
    wishItems.push(product);
    localStorage.setItem('wishItems', JSON.stringify(wishItems));
    checkIfInWishList();
    setShowPopup(true);
    setTextPopUp('התווסף למוצרים השמורים שלך!');
    setlistType('רשימת השמורים');
    setLinkPopUp('/wishlist');
  };

  const checkIfInWishList = () => {
    const wishItems = JSON.parse(localStorage.getItem('wishItems')) || [];
    const isInWishlist = wishItems.some(
      (item) => item.ProductName === product[0]?.ProductName
    );
    setIsInWishList(isInWishlist);
  };

  useEffect(() => {
    if (product.length > 0) {
      const wishItems = JSON.parse(localStorage.getItem('wishItems')) || [];
      const isInWishlist = wishItems.some(
        (item) => item.ProductName === product[0].ProductName
      );
      setIsInWishList(isInWishlist);
      setImage(product[0].Image);
    }
  }, [product]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/products.php?slug=${slug}`);
        const data = response.data;
        setProduct(data);
        checkIfInWishList();
      } catch (error) {
        console.log('Error fetching data:', error);
      }
    };

    fetchData();
  }, [slug]);

  useEffect(() => {
    if (product.length > 0) {
      setImage(product.Image);
      const originalText = product[0].ProductName;
      const truncatedText =
        originalText.length > 22
          ? originalText.slice(0, 22) + '...'
          : originalText;
      setShortext(truncatedText);
    }
  }, [product]);

  useEffect(() => {
    if (showPopup) {
      const popupTimer = setTimeout(() => {
        setShowPopup(false);
      }, 5000);

      return () => clearTimeout(popupTimer);
    }
  }, [showPopup]);

  const handleThumbnailClick = (selectedImage) => {
    setImage(selectedImage);
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta
          property="og:image"
          content={`../upload/images/products/${pageImage}`}
        />
      </Helmet>
      <div className="productPage container">
        {product.length > 0 ? (
          <>
            {showPopup && (
              <div className="popup">
                <div className="popup-content animate__animated animate__fadeIn">
                  <div className="fw-bold">{product[0].ProductName}</div>
                  <p>{textPopUp}</p>
                  <Link to={linkPopUp}>
                    <button className="btn btn-primary">
                      לצפייה ב{listType}
                    </button>
                  </Link>
                </div>
              </div>
            )}
            <ul className="ullehem">
              <li className="list-group-lehem">
                <a href="/">דף הבית</a>
              </li>
              /<li className="list-group-lehem">קטגוריה</li>/
              <li className="list-group-lehem">{shortext}</li>
            </ul>
            <div className="container mb-5 product_info">
              <div className="">
                <div className="row g-0">
                  <div className="col-md-6">
                    <div className="d-flex flex-column justify-content-center">
                      <div className="main_image">
                        <img
                          src={`../upload/images/products/${
                            image != null ? image : product[0].Image
                          }`}
                          id="main_product_image"
                          width="350"
                          alt=""
                        />
                      </div>
                      <div className="thumbnail_images">
                        <ul id="thumbnail">
                          <li>
                            <img
                              onClick={() =>
                                handleThumbnailClick(product[0]?.Image1)
                              }
                              src={`../upload/images/products/${product[0]?.Image1}`}
                              width="70"
                              alt=""
                            />
                          </li>
                          <li>
                            <img
                              onClick={() =>
                                handleThumbnailClick(product[0]?.Image2)
                              }
                              src={`../upload/images/products/${product[0]?.Image2}`}
                              width="70"
                              alt=""
                            />
                          </li>
                          <li>
                            <img
                              onClick={() =>
                                handleThumbnailClick(product[0]?.Image3)
                              }
                              src={`../upload/images/products/${product[0]?.Image3}`}
                              width="70"
                              alt=""
                            />
                          </li>
                          <li>
                            <img
                              onClick={() =>
                                handleThumbnailClick(product[0]?.Image4)
                              }
                              src={`../upload/images/products/${product[0]?.Image4}`}
                              width="70"
                              alt=""
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="p-3 right-side">
                      <div className="d-flex justify-content-between align-items-center">
                        <h1 className="title_ProductName">
                          {product[0].ProductName}
                        </h1>
                        <button
                          className={`heart ${isInWishList ? 'check' : ''}`}
                          disabled={isInWishList}
                          onClick={() => addToWishList(product[0])}
                        >
                          <i
                            className={`bi bi-heart${
                              isInWishList ? '-fill check' : ''
                            }`}
                          ></i>
                        </button>
                      </div>
                      <div className="mt-2 pr-3 content">
                        <p>{product[0].Description}</p>
                      </div>
                      <div className="pricesPage">
                        <span>מחיר רגיל:</span>
                        <h4>₪{product[0].Price}</h4>
                        <span>מחיר מבצע:</span>
                        <h4>₪{product[0].SalePrice}</h4>
                      </div>

                      <div className="ratings d-flex flex-row align-items-center">
                        <div className="d-flex flex-row reviewsBlock">
                          <i className="bi bi-star-fill"></i>
                          <i className="bi bi-star-fill"></i>
                          <i className="bi bi-star-fill"></i>
                          <i className="bi bi-star-fill"></i>
                          <i className="bi bi-star"></i>
                        </div>
                        <span>{product[0].NumReviews} ביקורות</span>
                      </div>
                      <div className="buttons d-flex flex-row mt-5 gap-3">
                        <button
                          className="btn btn-outline-dark"
                          onClick={() => buyNow(product[0])}
                        >
                          קנה עכשיו
                        </button>
                        <button
                          className="btn btn-dark"
                          onClick={() => addToCart(product[0])}
                        >
                          הוספה לסל
                        </button>
                      </div>
                      <div className="search-option">
                        <i className="bx bx-search-alt-2 first-search"></i>
                        <div className="inputs">
                          <input type="text" name="" />
                        </div>
                        <i className="bx bx-share-alt share"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <RelatedProducts
              category={product[0].CategoryID}
              proid={product[0].id}
            />
          </>
        ) : (
          <Loading />
        )}
      </div>
    </>
  );
}

export default ProductPage;
