import React, { useEffect, useState } from 'react';
import axios from 'axios';

function EditCategories() {
  const [formData, setFormData] = useState({
    id: '',
    name: '',
    icon: '',
  });

  useEffect(() => {
    axios
      .get('/api/categories.php')
      .then((response) => {
        const categoryData = response.data;
        setFormData(categoryData);
      })
      .catch((error) => {});
  }, []);
  return (
    <div class="container">
      <link
        href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css"
        rel="stylesheet"
      />
      <h1>עריכת קטגוריה</h1>
      <form
        method="PUT"
        action="/api/categories.php"
        enctype="multipart/form-data"
      >
        <div className="mb-3">
          <label className="form-label">שם הקטגוריה</label>
          <input
            type="text"
            className="form-control"
            name="name"
            value={formData.name}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">אייקון קטגוריה</label>
          <div className="blockquote-footer">
            אייקון הקטגוריה נלקח מחבילת האייקון של Bootstrap
          </div>
          <a href="https://icons.getbootstrap.com/">קישור לסיפירה</a>
          <input type="text" className="form-control" name="icon" />
        </div>
        <button type="submit" className="btn btn-primary">
          הוספת קטגוריה
        </button>
      </form>
    </div>
  );
}

export default EditCategories;
