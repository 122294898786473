import React, { useEffect, useState } from 'react';
import "../css/footer.css";
import axios from 'axios';

const Footer = () => {

      const [pixels, setPixels] = useState([]);

    useEffect(() => {
        const getPixels = async () => {
            try {
                const res = await axios.get('./api/pixels.php');
                const data = res.data;
                setPixels(data[0]);
            } catch (error) {
                console.log(error);
            }
        };
        getPixels();
    }, []);

  return (
    <footer className="text-white text-center text-lg-end">
      <div className="container p-4">
        <div className="row mt-4">
          <div className="col-lg-4 col-md-12 mb-4 mb-md-0">
            <h5 className="text-uppercase mb-4">אודותינו</h5>

            <p>
              טרנד מובייל" הוא אתר חנות המתמחה במחשבים וסמארטפונים, ומציע"
              ללקוחותיו מגוון רחב של מוצרים במחירים משתלמים. באתר, תוכלו למצוא
              מגוון של מוצרים חדישים ומתקדמים מכל המותגים המובילים בשוק.
            </p>

            <div className="mt-4">
              <a className="iconSocial">
                <i className="bi bi-facebook"></i>
              </a>

              <a className="iconSocial">
                <i className="bi bi-twitter"></i>
              </a>

              <a className="iconSocial">
                <i className="bi bi-google"></i>
              </a>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 mb-4 mb-md-0">
            <h5 className="text-uppercase mb-4 pb-1">חפשו מוצר כלשהו</h5>

            <div className="form-outline form-white mb-4">
              <input
                type="text"
                id="formControlLg"
                className=""
              />
              <input className="form-label btnsrc" type="submit" htmlFor="formControlLg" value={"חיפוש"}/>
                
              
              <div className="form-notch">
                <div className="form-notch-leading"></div>
                <div className="form-notch-middle"></div>
                <div className="form-notch-trailing"></div>
              </div>
            </div>

            <ul className="fa-ul">
              <li className="mb-3">
                <span className="fa-li">
                  <i className="bi bi-house-door"></i>
                </span>
                <span className="ms-2">בן יהודה 13, שדרות</span>
              </li>
              <li className="mb-3">
                <span className="fa-li">
                  <i className="bi bi-envelope"></i>
                </span>
                <span className="ms-2">info@trendmobile.co.il</span>
              </li>
              <li className="mb-3">
                <span className="fa-li">
                  <i className="bi bi-phone-vibrate"></i>
                </span>
                <span className="ms-2">054-205-2019</span>
              </li>
            </ul>
          </div>

          <div className="col-lg-4 col-md-6 mb-4 mb-md-0">
            <h5 className="text-uppercase mb-4">שעות פתיחה</h5>

            <table className="">
              <tbody className="">
                <tr>
                  <td>ראשון - חמישי</td>
                  <td>09:00 - 20:00</td>
                </tr>
                <tr>
                  <td>ימי שישי</td>
                  <td>09:00 - 14:00</td>
                </tr>
                <tr>
                  <td>מוצאי שבת</td>
                  <td>21:00 - 23:00</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="text-center p-3">
        <a className="text-white" href="https://trend-mobile.co.il/">
          Trend Mobile
        </a>
      </div>
      <script>{pixels.footer}</script>
    </footer>
  );
};

export default Footer;
