import React, { useEffect, useState } from 'react';

function Orders() {
  return (
    <div className="container">
      <h1>הזמנות</h1>
    </div>
  );
}

export default Orders;
